.promo-items {
    margin-bottom:79px;
    .promo-item {
        text-align: center;
        line-height: 2em;
        i {
            font-size: 1.75em;
            color: #e2c268; 
            margin-bottom:10px;
            display: block;
        }
    }
}