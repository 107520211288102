// This stylesheet is for anything Foundation 6 core-related, which could be reused in other Foundation-based websites.

/* Reveal Modal */
@include breakpoint(small only) {
  body.is-reveal-open {
    min-height: 100%;
    position: fixed;
    user-select: none;
  }
  .reveal {
    padding-bottom: 20%;
    -webkit-overflow-scrolling: touch;
    overflow-y: visible;
  }
}

/* anchors */
a.anchor {
  display: none;
}

.sticky {
  position: relative;
  top: 0;
}