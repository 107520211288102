// Table of Contents:
//  1. Global
//  -----------------------------



// 1. Global
// ---------
.padding-all {
  padding: 1rem;
  @include breakpoint(medium) {
    padding: 5%;
  }
}

#main-content {
  min-height:calc(100vh - 84px - 125px);
}