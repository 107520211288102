.popup-wrapper {
  position: relative;
  background: #FFF;  
  width: auto;
  max-width: 1009px;
  margin: 20px auto;
  @include breakpoint(small only) {
    h2 {
      margin-top: 40px;
      margin-bottom: 10px;
    }
  }
}
@include breakpoint(small only) {
  #edit-popup {
    padding-bottom: 40px;
  }
}