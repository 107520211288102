.footer-nav-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  .copyright {
    font-size: 0.6875rem; // 11px
  }
}
.footer-bottom-wrapper {
  padding: 15px 0;
  box-shadow: 0 0 5px rgba(0,0,0,.05);
}
.footer-nav {
  .menu {
    font-size: 0.75rem; // 12px
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 400;
    a {
      padding-left: 0;
      padding-right: 0;
      margin-right: 16px;
      line-height: 3px;
      color: $black;
      border-bottom: 2px solid transparent;
      &:hover, &:focus {
        color: $black;
        border-bottom: 2px solid $gold;
      }
    }
    li:last-child {
      a {
        padding-right: 0;
        margin-right: 0;
      }
    }
    li.active {
      a {
        color: $black;
        background: none;
        &:hover, &:focus {
          color: $gold;
          border-bottom: 2px solid $gold;
        }
      }
    }
  }
  @include breakpoint(small only) {
    text-align: center;
    .menu {
      justify-content: center;
    }
  }
}
.copyright-wrapper {
  font-size: 0.7875rem; // 12.6px
  .copyright {
    font-weight: 400;
  }
  .policy-links {
    list-style: none;
    margin-bottom: 0;
    li {
      display: inline-block;
      a {
        color: $black;
        &:hover, &:focus {
          color: $gold;
        }
      }
      &:before {
        content: "|";
        color: $gray;
        margin-left: 5px;
        margin-right: 5px;
      }
      &:first-child {
        &:before {
          content: none;
        }
      }
    }
    @include breakpoint(small only) {
      margin-left: 0;
      text-align: center;
    }
  }
}
.support-wrapper {
  button, .button {
    margin-bottom: 0;
  }
  @include breakpoint(medium) {
    text-align: right;
  }
  @include breakpoint(small only) {
    margin: 25px 0 15px;
  }
}
.social-icons {
  ul.social-icon-list {
    list-style: none;
    text-align: right;
    margin-bottom: 0;
    li {
      display: inline-block;
      margin-left: 15px;
      &:first-child {
        margin-left: 0;
      }
      a {
        color: $black;
        font-size: 12px;
        &:hover, &:focus {
          color: $gold;
        }
      }
    }
  }
}

// Alignments
@include breakpoint(medium) {
  .copyright-wrapper, .footer-nav {
    display: flex;
    align-items: center;
  }
}
@include breakpoint(small only) {

  .copyright, .support-wrapper, 
  .social-icons ul.social-icon-list {
    text-align: center;
  }
}