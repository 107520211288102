// Reservation Breadcrumbs

.reservation-breadcrumbs-wrapper {
  margin-top: 18px;
  padding: 5px 0;
  box-shadow: 0 0 5px rgba(0,0,0,.05);
  // border-top: 1px solid $gray;
}
.grid-container.no-breadcrumbs {
  margin-top: 18px;
  // box-shadow: 0 0 5px rgba(0,0,0,.05);
  .main, .sidebar {
    padding-top:15px;
  }
  .sidebar {
    ol {
      font-weight: 300;
    }
  }
}
.reservation-breadcrumbs {
  .menu {
    font-size: 0.75rem; // 12px
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 400;
    li {
      display: flex;
      align-items: center;
      color: $ada-gray;
      line-height: 1;
      &.not-active {
        border-bottom: 2px solid transparent;
      }
      // &:after {
      //   content: "\f105";
      //   font-family: FontAwesome;
      //   margin-left: 1rem;
      //   margin-right: 1rem;
      //   color: $black;
      // }
      &.sep {
        padding-left:10px;
        padding-right:10px;
      }
      &.active {
        font-weight:500;  
        color:$gold;      
      }
      a {
        padding-left: 0;
        padding-right: 0;
        line-height: 3px;
        color: $black;
        border-bottom: 2px solid transparent;
        &:hover, &:focus {
          color: $gold;
          border-bottom: 2px solid $gold;
        }
      }
      &:last-child {
        a {
          padding-right: 0;
          margin-right: 0;
        }
        &:after {
          content: none;
        }
      }
      &.active {
        a {
          color: $gold;
          background: none;
          &:hover, &:focus {
            color: $gold;
            border-bottom: 2px solid $gold;
          }
        }
      }
    }
  }
}

// Reservation Main Content
.reservation-overview {
  @include breakpoint(medium) {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 15px 1fr;
    grid-template-columns: repeat(2, 1fr);
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 0px;
    > *:nth-child(1) {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }
    > *:nth-child(2) {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }
    .button-wrapper {
      text-align: right;
    }
  }
  @include breakpoint(small only) {
    p {
      margin-bottom: 0;
    }
    .button-wrapper {
      margin-bottom: 40px;
    }
  }
}
.submit.button-wrapper {
  margin-top: 40px;
  @include breakpoint(medium) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @include breakpoint(small only) {
    input[type=submit] {
      display: block !important;
      margin-top: 30px;
      width: 100%;
    }
  }
}
#payment-form {
  p:not(.help-text) {
    margin-bottom: 20px;
  }
}

// Reservation Sidebar

.reservation-page {
  .sidebar {
    .reservation-image {
      margin-bottom: 25px;
      img {
        width: 100%;
        height: auto;
      }
    }
    hr {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .tablegrid-wrapper {
      .column-row {
        + .column-row {
          margin-top: 10px;
        }
        .second-column {
          text-align: right;
        }
      }
    }
  }
}


// To put sidebar on top for small devices
@include breakpoint(medium down) {
  .reservation-page {
    .main-content {
      > .grid-container {
        > .grid-x {
          flex-direction: column-reverse;
          .cell.sidebar {
            margin-top: 0;
            border-top: none;
            padding-top: 0;
            padding-bottom: 40px;
            margin-bottom: 40px;
            border-bottom: 1px solid $gray;
          }
        }
      }
    }
  }
}


// Credit Card Icons
.credit-card-icons {
  display: inline-block;
  .fab {
    font-size: 1.125rem; // 18px
    margin-top: 10px;
    &.fa-cc-visa {
      color: #1A1E71;
    }
    &.fa-cc-mastercard {
      color: #EB091A;
    }
    &.fa-cc-amex {
      color: #116ED1;
    }
    &.fa-cc-discover {
      color: #ff6000;
    }
    &.fa-cc-jcb {
      color: #083893;
    }
    &.fa-cc-diners-club {
      color: #0D4E97;
    }
    &.fa-cc-apple-pay {}
  }
}
@include breakpoint(small only) {
  .mobile-reverse {
    display: flex;
    flex-direction: column-reverse;
    margin-top:0px !important;
    padding:0px  !important;
    .sidebar {
      margin-top:0px;
      margin-bottom:0px;
      padding-bottom: 10px;
      box-sizing: content-box;
      .boxed {
        margin:0px;
        padding:0px;        
      }
    }
  }
}
@include breakpoint(medium only) {
  .mobile-reverse {
    display: flex;
    flex-direction: column-reverse;
    margin-top:0px !important;
    padding:0px  !important;
    .sidebar {
      margin-top:0px;
      margin-bottom:0px;
      padding-bottom: 10px;
      box-sizing: content-box;
      .boxed {
        margin:0px;
        padding:0px;        
      }
    }
  }
}