// Gradients

@mixin black-overlay {
  // same as koolina.com original
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0+59,0.65+91,0.65+100 */
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 59%, rgba(0,0,0,0.65) 91%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 59%,rgba(0,0,0,0.65) 91%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 59%,rgba(0,0,0,0.65) 91%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
}
@mixin black-overlay2 {
  // same as koolina.com overriding the original
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.5+0,0.5+10,0.08+45,0+50,0.08+55,0.5+90,0.5+100 */
  background: -moz-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 10%, rgba(0,0,0,0.08) 45%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.08) 55%, rgba(0,0,0,0.5) 90%, rgba(0,0,0,0.5) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 10%,rgba(0,0,0,0.08) 45%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.08) 55%,rgba(0,0,0,0.5) 90%,rgba(0,0,0,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 10%,rgba(0,0,0,0.08) 45%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.08) 55%,rgba(0,0,0,0.5) 90%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#80000000',GradientType=0 ); /* IE6-9 */
}
@mixin black-overlay3 {
  // black-overlay2 but without transparent middle
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.5+0,0.5+10,0.08+45,0.08+55,0.5+90,0.5+100 */
  background: -moz-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 10%, rgba(0,0,0,0.25) 45%, rgba(0,0,0,0.25) 55%, rgba(0,0,0,0.5) 90%, rgba(0,0,0,0.5) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 10%,rgba(0,0,0,0.25) 45%,rgba(0,0,0,0.25) 55%,rgba(0,0,0,0.5) 90%,rgba(0,0,0,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 10%,rgba(0,0,0,0.25) 45%,rgba(0,0,0,0.25) 55%,rgba(0,0,0,0.5) 90%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#80000000',GradientType=0 ); /* IE6-9 */
}
@mixin black-overlay-ada {
  // black-overlay3 but darker
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0.65+10,0.35+30,0.35+46,0.35+55,0.65+90,0.65+100 */
  background: -moz-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0.65) 10%, rgba(0,0,0,0.35) 30%, rgba(0,0,0,0.35) 46%, rgba(0,0,0,0.35) 55%, rgba(0,0,0,0.65) 90%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.65) 10%,rgba(0,0,0,0.35) 30%,rgba(0,0,0,0.35) 46%,rgba(0,0,0,0.35) 55%,rgba(0,0,0,0.65) 90%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.65) 10%,rgba(0,0,0,0.35) 30%,rgba(0,0,0,0.35) 46%,rgba(0,0,0,0.35) 55%,rgba(0,0,0,0.65) 90%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
}