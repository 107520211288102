.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  &.element-focusable {
    &:active {
      position: static !important;
      clip: auto;
      overflow: visible;
      height: auto;
    }
    &:focus {
      position: static !important;
      clip: auto;
      overflow: visible;
      height: auto;
    }
  }
}
a, button, .button {
  &:focus {
    outline: -webkit-focus-ring-color auto 5px !important;
  }
}