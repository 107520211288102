// Popup Window
.white-popup {
  position: relative;
  background: #FFF;  
  width: auto;
  max-width: 1009px;
  margin: 20px auto;
  .mfp-close {
    color:#fff !important;
  }
  @include breakpoint(large) {
    .mfp-close{
      // top: -43px;
      // right: -12px;    
      &:active{
        // top: -43px;
        // right: -12px;
      }
      i{
        color: $white;
      }
    }
  }
}