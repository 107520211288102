.trans-grid {
    a {
        text-decoration: none !important;
        .activity-container {
            text-align:center;
            h2 {
                text-decoration: none !important;
            }
        }
    }
    .activity-container {
        padding:10px 0px 30px 0;
        h2 {
            font-size:1rem;
            text-transform: capitalize;
            margin-top:12px;
        }
    }
}