.hero-section {
  // For Full-Screen Effect
  position: relative;
  &.homepage,
  &.homepage .slick-slider,
  &.homepage .slick-slider .slick-list,
  &.homepage .slick-slider .slick-track,
  &.homepage .slider-wrapper .slick-slide,
  &.homepage .slider-wrapper .slick-slide > div,
  &.subpage .slider-wrapper .banner-wrapper {
    height: 100vh;
    min-height: 848px;
  }
  .slider-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .slick-list {
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        @include black-overlay2;
      }
    }
    .fullscreen {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .slick-slide {
      max-height: 100vh;
      min-height: 848px;
      @extend .fullscreen;
    }
  }
  .hero-content-wrapper {
    position: relative;
    z-index: 2;
    color: $white;
    width: 100%;
    height: 100%;
    h2 {
      text-align: center;
    }
    @include breakpoint(small only) {
      padding-top: 80px;
    }
    @include breakpoint(medium) {
      .form-wrapper {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .hero-description {
      color: $white;
      margin-bottom: 30px;
      @include breakpoint (medium) {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
      @include breakpoint(small only) {
        margin-top: 40px;
        .cell {
          + .cell {
            margin-top: 25px;
          }
          h2, h3 {
            margin-bottom: 3px;
            font-size:1.3rem;
          }
          p {
            font-size: 0.7875rem; // 12.6px
            line-height: 1.3;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  // For homepage to not use fullscreen on small devices
  @include breakpoint(small only) {
    &.homepage,
    &.homepage .slick-slider,
    &.homepage .slick-slider .slick-list,
    &.homepage .slick-slider .slick-track,
    &.homepage .slider-wrapper .slick-slide,
    &.homepage .slider-wrapper .slick-slide > div,
    &.subpage .slider-wrapper .banner-wrapper {
      height: 100%;
      max-height: 100%;
    }
    &.homepage {
      padding-bottom: 80px;
    }
  }

  // Give Subpage banner a height
  &.subpage,
  &.subpage .slider-wrapper .slick-slide,
  &.subpage .slider-wrapper .banner-wrapper {
    height: 50vh;
    max-height: 540px;
    min-height: auto;
  }
}
// body.index {
//   .hero-section {
//     .slider-wrapper {
//       &:before {
//         @include black-overlay3;
//       }
//     }
//   }
// }
body.index {
  .hero-section {
    .hero-content-wrapper {
      .form-wrapper {
        > .cell {
          background: rgba(0,0,0, 0.65);
          padding: 30px;
        }
      }
    }
  }
}

.hero-logos {
  max-width:770px;
  margin-left:auto;
  margin-right:auto;
  margin-top:30px;
  margin-bottom:30px;
  figure {
    text-align:center;
    img {
      width: 125px;
      height:auto;
      opacity: 0.95;
    }
  }
}
.hero-section {
  &.single-slide {
    .banner-wrapper {
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        @include black-overlay2;
      }
    }
  }
}
