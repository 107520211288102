label {
  position: relative;
  font-size: 13px;
  font-weight: 700;
  .fa-calendar-alt.start {
    font-size: 1rem;
    position: absolute;
    right: 10px;
    top: 34px;
    color: $gold;
    font-weight: 400;
    pointer-events: none;
  }
}
.form-row {
  margin-bottom: 40px;
}

input[type="submit"] {
  margin-bottom: 0;
}


.hero-section {
  .hero-form-inner {
    margin-top: 20px;
  }
  .hero-form-text {
    margin-bottom: 25px;
    text-align: center;
  }
  label {
    color: $white;
  }
  .button-wrapper {
    text-align: center;
  }
  input[type="submit"] {
    width: 60%;
  }
}
.hero-section {
  .reservation-edit  {
    .fields-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit,minmax(175px,1fr));
      grid-gap: 20px 20px;
      gap: 20px 20px;
      @include breakpoint(medium only) {
        grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
      }
    }
    
  }
}
.sidebar-content {
  .reservation-edit {
    .fields-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit,minmax(125px,1fr));
      grid-gap: 20px 20px;
      gap: 20px 20px;
    }
  }
}

// Mailchimp
.mailchimp {
  .input-group {
    display: block;
    margin-bottom: 40px;
    p {
      margin-bottom: 15px;
    }
    ul {
      list-style: none;
      li {
        input[type=checkbox] {
          margin-bottom: 0;
          + label {
            font-weight: 400;
          }
        }
      }
    }
  }
}


/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
 .credit-card-payment {
  .StripeElement {
    box-sizing: border-box;
    height: 40px;
    padding: 10px 12px;
    border: 1px solid #cacaca;
    background-color: white;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
    &:focus {
      border: 1px solid #8a8a8a;
      box-shadow: 0 0 5px #cacaca;
      -webkit-transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
      transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
    }
  }
  .StripeElement--focus {
    border: 1px solid #8a8a8a;
    box-shadow: 0 0 5px #cacaca;
    -webkit-transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  }
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
 }
