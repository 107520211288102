// Table of Contents:
//  1. Main Containers
//  2. Global Responsive
//  3. Homepage
//  4. Subpage
//  -----------------------------


// 1. Main Containers
// ------------------

/* Global Sections */
div[class*="-section"]:not(.hero-section) {
  padding-top: 60px;
  padding-bottom: 60px;
}
.main-content {
  padding: 100px 0;
  @include breakpoint(small only) {
    padding: 50px 0;
  }
  > .grid-container {
    > .grid-x {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      > .cell {
        > .grid-x + .grid-x {
          margin-top: 40px;
        }
      }
    }
  }
}
/* Sidebar */
.sidebar {
  @include breakpoint(large) {
    border-left: 1px solid $gray;
    padding-left: 30px;
  }
  @include breakpoint(medium down) {
    border-top: 1px solid $gray;
    padding-top: 30px;
    margin-top: 40px;
  }
}

/* 2nd layouts */
.layout2 {
  .main-content {
    padding-bottom: 0;
    .heading-wrapper {
      h1 {
        text-align: center;
        @include breakpoint(medium) {
          font-size: 5rem; // 80px
        }
      }
    }
    .body-wrapper {
      background: $off-white;
      padding-top: 100px;
      padding-bottom: 100px;
      @include breakpoint(small only) {
        padding-top: 50px;
        padding-bottom: 50px;
      }
    }
  }
  .sidebar {
    border: none;
    padding-left: 0;
    .boxed {
      padding-bottom: 50px;
      .boxed-inner {
        border: 1px solid $gray;
      }
      .sidebar-content {
        padding: 30px 20px;
        background: $white;
      }
      .side-note {
        margin: 0;
        padding: 20px;
        background: $off-white;
      }
    }
    @include breakpoint(large) {
      margin-bottom: 100px;
    }
    @include breakpoint(medium down) {
      margin-bottom: 50px;
    }
  }
}
/* 3rd layout */
.layout3 {
  .main-content {
    margin-top: 100px;
    padding: 0;
    position: relative;
  }
  .lounge-main-body {
  }
  .lounge-second-body {
    background: $off-white;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @include breakpoint(large) {
    .lounge-main-body, .lounge-second-body {
      .cell {
        position: relative;
        z-index: 2;
      }
    }
    .sidebar-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  .sidebar {
    border: none;
    padding-left: 0;
    .boxed {
      padding-bottom: 50px;
      .boxed-inner {
        border: 1px solid $gray;
      }
      .sidebar-content {
        padding: 30px 20px;
        background: $white;
      }
      .side-note {
        margin: 0;
        padding: 20px;
        background: $off-white;
      }
    }
    @include breakpoint(large) {
      margin-bottom: 100px;
    }
    @include breakpoint(medium down) {
      margin-bottom: 50px;
    }
  }
}


/* Section Teasers */
div[class*="-teasers-section"] {
  .section-teaser {
    @include breakpoint(medium down) {
      margin-bottom: 30px;
    }
    .image-wrapper {
      margin-bottom: 10px;
      img {
        width: 100%;
      }
    }
    .text-wrapper {
      background: $white;
      padding: 20px;
      .teaser-title {
        padding-bottom: 10px;
      }
      .teaser-button {
        padding-top: 20px;
      }
    }
    
  }
}





// 2. Global Responsive
// --------------------



// 3. Homepage
// -----------

@include breakpoint(small only) {
  .hero-section.homepage {
    .hero-content-wrapper {
      padding-top: 120px;
      h2 {
        font-size: 3rem;
      }
    }
    .slider-wrapper {
      .slick-slider {
        .slick-prev, .slick-next {
          top: unset;
          bottom: 10px;
          transform: unset;
        }
      }
    }
  }
}


// 4. Subpage
// ----------