// Table of Contents:
//  1. Global
//  2. Sections
//  3. Buttons
//  4. Accordions
//  -----------------------------
// Lato: Thin 100, Light 300, Regular 400, Bold 700, Black 900



// 1. Global
// ---------

.main-content {
  h1 {
    margin-bottom: 40px;
  }
  a:not(.button) {
    color: $black;
    text-decoration: underline;
    font-weight:500;
    &:hover, &:focus {
      text-decoration: none;
    }
  }
  hr {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
h2, h3 {
  font-family: $body-font-family;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  text-transform: uppercase;
  margin-bottom: 15px;
  a {
    color: $gold;
  }
}
.hero-section .form-wrapper h2 {
  font-family: $header-font-family;
  font-size: 3.9375rem; // 63px
  line-height: 1.1;
  text-transform: none;
}
h4 {
  font-size: 25.2px;
  line-height: 1.3;
}
p, .main-content ul, .main-content ol {
  font-size: 1.05rem;
  line-height: 1.5;
  font-weight: 300;
  margin-bottom: 40px;
}
p + .callout,
ul + p,
ol + p {
  margin-top: 40px;
  margin-bottom: 40px;
}
.main-content {
  ul, ol {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.main-content ul:not(.accordion) li + li,
.main-content ol li + li {
  margin-top: 10px;
}
.callout {
  text-align: center;
  + h2, + h3 {
    margin-top: 40px;
  }
  &.clear { 
    background:transparent;
    border: none;
    &.registration-policy {
      font-family: "Lato", sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 1.4;
      text-transform: uppercase;
      margin-bottom: 15px;
      em {
        margin:0 6px;
        border-bottom: 1px solid $gold;
      }

      .important-note {
        color: #956e00;
      }
    }
  }
}
.reg-form-in-hero {
  .callout {
    &.registration-policy {
      color:#ffffff;

      .important-note {
        color: #dba50a;
      }
    }
  }
}
.callout {
  &.registration-policy {
    &.notice-highlight {
      border:1px solid $gold;
      margin-top: 15px;
    }
  }
}
select {
  &.notice-highlight {
    border:2px solid $gold;
  }
}
@include breakpoint(medium down) {
  body:not(.index) {
    .main-content {
      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}


// 2. Sections
// -----------

.section-title {
  h2 {
    font-size: 1.625rem; // 26px
    font-weight: 600;
    text-transform: uppercase;
    color: $gold;
    padding-bottom: 35px;
    &:after {
      content: "";
      display: block;
      width: 56px; 
      height: 7px;
      background: $gold;
      margin-top: 2px;
    }
  }
}
.white-text {
  color: $white;
  .section-title {
    h2 {
      color: $white;
      &:after {
        background: $white;
      }
    }
  }
}
.home-intro-links a, a.read-more {
  font-family: $body-font-family;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none !important;
  padding-bottom: 2px;
  border-bottom: 2px solid transparent;
  display: inline-block;
  &:hover, &:focus {
    border-bottom: 2px solid $gold;
  }
  i {
    color: #848484;
    margin-right: 7px;
  }
}
.amenities {
  font-weight: 300;
  margin-bottom: 40px;
  h2, h3 {
    margin-bottom: 1rem;
  }
  .cell {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    i[class*="fa-"] {
      width: 20px;
      margin-right: 8px;
      text-align: center;
    }
  }
}
.sidebar {
  p {
    margin-bottom: 25px;
  }
}
.side-note {
  margin-top: 40px;
  display: flex;
  align-items: center;
  .icon {
    font-size: 42px;
    margin-right: 10px;
    align-self: center;
  }
  .text-wrapper {
    h2, h3 {
      margin-bottom: 3px;
    }
    p {
      font-size: 0.7875rem; // 12.6px
      line-height: 1.3;
      margin-bottom: 0;
    }
  }
}
.popup-wrapper {
  h2 {
    font-family: $header-font-family;
    font-size: 1.5rem;
    text-transform: initial;
    margin-bottom: 40px;
    line-height: 1.4;
    margin-top: 0;
    @include breakpoint(medium) {
      font-size: 3rem;
    }
  }
}


// 3. Buttons
// ----------

button, .button {
  // &:not(.toggle-button):not(.close-button):not(.slick-arrow):not([role="tab"]):not(.mfp-close) {
  //   // font-size: 11.2px;
  //   font-weight: 600;
  //   letter-spacing: 0.5px;
  //   line-height: 38px;
  //   text-transform: uppercase;
  //   display: inline-block;
  //   text-decoration: none;
  //   color: $white;
  //   background: $gold;
  //   border: 2px solid $gold;
  //   height: 40px;
  //   padding: 0 20px;
  //   &:hover, &:focus {
  //     background: scale-color($gold, $lightness: -20%);
  //     border-color: scale-color($gold, $lightness: -20%);
  //   }
  //   &.outline {
  //     background: transparent;
  //     color: $gold;
  //     &:hover, &:focus {
  //       background: $gold;
  //       border-color: $gold;
  //       color: $white;
  //     }
  //   }
  // }
}
.mfp-close {
  cursor: pointer !important; 
  &:hover{
    cursor: pointer !important; 
  }
}

/* Footer reservation button on Lounge Details page */
body.lounge {
  position: relative;
  padding-bottom: 40px;
  .button-wrapper.full-width {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 3;
    a.button {
      margin-bottom: 0;
      width: 100%;
    }
  }
}


// 4. Accordions
// -------------

ul.accordion {
  li.accordion-item {
    border-bottom: 1px solid $gray;
    a.accordion-title {
      font-size: 1.05rem;
      line-height: 1.5;
      font-weight: 300;
      color: $black;
      font-weight: 400;
      text-decoration: none;
      border: 0;
      border-bottom: 2px solid transparent;
      &:before {
        top: unset;
        right: unset;
        margin-top: 0;
        left: 0;
        color: $gold;
        font-weight: 900;
      }
      &:hover, &:focus {
        background: transparent;
        border-bottom: 2px solid $gold;
      }
    }
    .accordion-content {
      border: 0;
    }
  }
}
