/* Container */
.slick-slider {
  position: relative;
  margin-bottom: 0 !important;
}

/* Slick Slide */
.slick-slide {
  max-height: 704px;
  img {
    width: 100%;
    max-width: inherit;
    height: auto;
  }
  .slide-figure {
    .slide-caption {
      color: $white;
      position: absolute;
      bottom: 42px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      h2 {
        font-size: 2.5rem; // 40px
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 0;
      }
      h3 {
        font-size: 1.25rem; // 20px
        font-weight: 600;
        color: $white;
      }
      a.button {
        margin-top: 15px;
      }
    }
  }
}
// Footer slick slider
.slick-wrapper {
  .slick-slide {
    max-height: 339px;
    > div {
      height: 339px;
      position: relative;
      a {
        display:block;
        height: 339px;
        width:100%;
        img {
          position: absolute;
          bottom:0px;
          left:0px;
        }
      }
    }
    img {
      width: 100%;
      max-width: inherit;
      height: auto;
    }
    .slide-figure {
      .slide-caption {
        color: $white;
        position: absolute;
        bottom: 42px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        h2 {
          font-size: 2.5rem; // 40px
          font-weight: 600;
          text-transform: uppercase;
          margin-bottom: 0;
        }
        h3 {
          font-size: 1.25rem; // 20px
          font-weight: 600;
          color: $white;
        }
        a.button {
          margin-top: 15px;
        }
      }
    }
  }
}

// Modal Slider 
#carousel-popup {
  background:#333;
}
.modal-carousel {
  .slick-slide {
    > div {
      max-height: inherit;
      text-align:center;
      img {
        max-height: inherit;
        width: auto !important;
      }
    }
  }
}

// to add space between edge of screen
.slick-arrow {
  margin-left: 15px;
  margin-right: 15px;
}

/* Slick Arrows */
.slick-arrow {
  &:before {
    color: $black !important;
  }
  &.slick-prev {
    left: 0;
    z-index: 2;
    width: 25px;
    height: 47px;
    &:before {
      content: "";
      display: block;
      background: url("../img/theme/slider-arrow-prev-white.svg");
      background-size: contain;
      width: 25px;
      height: 47px;
    }
    &:hover, &:focus {
      &:before {
        background: url("../img/theme/slider-arrow-prev-gold.svg");
      }
    }
  }
  &.slick-next {
    right: 0;
    width: 25px;
    height: 47px;
    z-index: 3;
    &:before {
      content: "";
      display: block;
      background: url("../img/theme/slider-arrow-next-white.svg");
      background-size: contain;
      width: 25px;
      height: 47px;
    }
    &:hover, &:focus {
      &:before {
        background: url("../img/theme/slider-arrow-next-gold.svg");
      }
    }
  }
}
// To contain the slider arrows
.slider-wrapper.contained {
  .slick-arrow {
    @include breakpoint(xlarge) {
      &.slick-prev {
        left: calc(50vw - 610px);
      }
      &.slick-next {
        right: calc(50vw - 610px);
      }
    }
  }
}

/* Slick Dots */
.slick-dots {
  position: absolute;
  bottom: 25px !important;
  left: 0;
  z-index: 2;
  @include breakpoint(small only) {
    bottom: 15px !important;
  }  
  li {
    width: auto !important;
    height: auto !important;
    button {
      background: transparent !important;
      border: 1px solid $white !important;
      border-radius: 50%;
      text-indent: -9000px;
      margin: 0;
      height: 12px !important;
      width: 12px !important;
    }
    &.slick-active {
      button {
        background: $white !important;
      }
    }
  }
}

// Custom Slick Dots for this project
.slick-dots {
  li {
    margin: 0 15px !important;
    button {
      background: rgba(255,255,255,.8) !important;
      border: none !important;
      height: 15px !important;
      width: 15px !important;
    }
    &.slick-active {
      button {
        background: rgba(255, 255, 255, 0.8) !important;
        // outline: 3px solid $gold !important;
        box-shadow: 0 0 0 3px $gold;
      }
    }
  }
}
.nav-carousel {
  .slick-track {
    .slick-slide {
      > div {
        height: 310px; /* height of container */
        a {
          height: 310px; /* height of container */
        }
      }
      .slide-image {
        width: 100%; /* width of container */
        height: 310px; /* height of container */
        object-fit: cover;
        object-position: 0% 100%; /* try 20px 10px */ 
      }
      @include breakpoint(small only) {
        > div {
          height: 167px; /* height of container */
          a {
            height: 167px; /* height of container */
          }
        }
        .slide-image {
          width: 250px; /* width of container */
          height: 167px; /* height of container */
        }
      }
      @include breakpoint(medium only) {
        > div {
          height: 214px; /* height of container */
          a {
            height: 214px; /* height of container */
          }
        }
        .slide-image {
          width: 100%; /* width of container */
          height: 214px; /* height of container */
        }
      }
    }
  }
}


