// Custom to this project only
// ===========================

/* Main Navigation */
.has-banner header {
  position: absolute;
  z-index: 3;
  width: 100%;
}

header {
  .main-header-wrapper {
    padding-top: 13px;
    #logo {
      margin-bottom: 0;
      width: 107px;
      height: 71px;
    }

    .navigation {
      .dropdown {
        justify-content: flex-end !important;
        &.parent-menu {
          > li {
            margin-left: 40px;
            &:first-child {
              margin-left: 0;
            }
            a {
              color: $white;
              font-size: 0.7875rem; // 12.6px
              text-transform: uppercase;
              letter-spacing: 1px;
              padding: 0;
            }
          }
          .sub-menu {
            li {
              a {
                color: $gold;
              }
            }
          }
        }
      }
    }
  }
}
.no-banner {
  header {
    .main-header-wrapper {
      .navigation {
        .dropdown {
          &.parent-menu {
            > li {
              a {
                color: $black;
                text-shadow: none !important;
              }
              &.active {
                a {
                  color: $black !important;
                  background: none;
                }
              }
            }
          }
        }
      }
    }
  }
}


// Copy/paste from previous projects (just change colors)
// ======================================================

header {

  // 1. Global Navigation
  // --------------------

  .navigation {
    nav {

      // 2. Main Menu Dropdown
      // ---------------------

      &.main-nav {
        padding-top: 11px;
        ul.dropdown.menu {
          justify-content: center;
          > li[role="menuitem"] {
            a {
              text-shadow: 0 0 4px #333;
              padding-bottom:4px;
              border-bottom: 2px solid transparent;
              &:hover, &:focus {
                border-bottom: 2px solid $gold;
              }
            }
            &.active {
              > a {
                color: $white;
                background: none;
                font-weight:500;
                border-bottom: 2px solid $gold;
              }
            }
          }
          // 3. Submenu Dropdown
          // -------------------

          > li.is-dropdown-submenu-parent {
            &.is-active {
              a {
                color: $gold;
              }
            }
            &:first-child {
              margin-left: 0;
            }
            > a {
              color: $white;
              margin: 0px;
              &:after {
                display: none;
              }
              &:hover, &:focus {
                color: $gold;
              }
            }

            > ul.is-dropdown-submenu {
              top: 32px;
              text-align: left;
              li.is-submenu-item {
                //border-bottom: 1px solid $black;
                &.active a {
                  color: $gold;
                  background: none;
                }
                &:last-child {
                  border-bottom: none;
                }
                a {
                  color: $gold;
                  padding: 10px;
                  &:hover, &:focus {
                    background: $gold;
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// 4. Mobile Menu Toggle
// ---------------------

.mobile-toggle {
  background: transparent;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  &:hover, &:focus {
    background: none;
  }
  .toggle-button {
    font-size: 1.875rem; // 30px
    background: transparent;
    padding: 15px;
    margin: 0;
    color: $white;
    span {
      display: none;
      padding-left: 10px;
    }
  } 
}
.no-banner {
  .mobile-toggle {
    .toggle-button {
      color: $black;
    }
  }
}
.navigation {
  .close-button {
    padding: 0;
  }
}


// 5. Chevrons
// -----------

.mobile-nav {
  .vertical.menu {
    li {
      &.is-accordion-submenu-parent {
        position: relative;
        > a {
          position: relative;
          .sub-chev {
            position: absolute;
            right: 1rem;
            top: 10px;
            color: $gold;
          }
        }
        
      }
      &[aria-expanded="true"] {
        > a {
          .sub-chev {
            .fa-chevron-down {
              transform: scaleY(-1);
            }
          }
        }
      }
    }
  }
}

// 6. Mobile Menu Items
// --------------------

.mobile-nav {
  margin-top: 35px;
  li {
    a {
      font-size: 0.875rem; // 14px
      color: $black;
      letter-spacing: 0.5px;
      &:hover, &:focus {
        color: $gold;
      }
    }
    &.active {
      > a {
        color: $gold;
        background: none;
        font-weight: 600;
      }
    }
  }
  .is-accordion-submenu-parent {
    > a {
      &:hover, &:focus {
        color: $gold;
      }
    }
    &[aria-expanded="true"] {
      > a {
        color: $gold;
      }
      .submenu.nested {
        margin-left: 0;
        border-left: 5px solid $gold;
        > li.is-submenu-item {
          > a {
            color: $black;
            &:hover, &:focus {
              background: $gold;
              color: $white;
            }
          }
        }
        > li.active {
          > a {
            color: $gold;
            background: none;
            font-weight: 600;
          }
        }
      }
    }
  }
}